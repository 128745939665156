<template lang="pug">
    section#mosaicoIndustrias
        .wrapper
            .content
                Editor(:contents="editorTop")
            ul.mosaico
                li(v-for="item, index in items" :class="`_${index+1}`")
                    img(:src="item.image")
                    span {{ item.caption }}
            .botContent
                Editor(:contents="editorBottom")
                a(:href="contents.content.buttonLink").squareButton {{ contents.content.buttonName }}
</template>

<script>
import { props } from '@/mixins/component'
import Editor from '@sections/Pagina/Editor/Editor'

export default {
    name: "section-industria-mosaico",
    props,
    components: {
        Editor
    },
    computed: {
        editorTop() {
            return {content: this.contents.content.editorTop}
        },
        editorBottom() {
            return {content: this.contents.content.editorBottom}
        },
        items() {
            return this.contents.content.mosaico.map(item => ({
                ...item,
                image: `${process.env.VUE_APP_FILE_API_ROUTE}/medias/media/${item.image}`
            }))
        }
    },
}
</script>

<style lang="stylus" scoped src="./IndustriaMosaico.styl"></style>